import basicBannerFragment from 'api/queries/fragments/basicBannerFragment'
import mediaFragment from 'api/queries/fragments/mediaFragment'
import descriptionWithIconFragment from 'api/queries/fragments/descriptionWithIconFragment'
import halfBannersFragment from 'api/queries/fragments/halfBannersFragment'
import carouselBigButtonFragment from 'api/queries/fragments/carouselBigButtonFragment'
import setBigButtonFragment from 'api/queries/fragments/setBigButtonFragment'
import linkListLeftImageFragment from 'api/queries/fragments/linkListLeftImageFragment'
import linkWithTitleAndSubtitleFragment from 'api/queries/fragments/linkWithTitleAndSubtitleFragment'
import accessMenuFragment from 'api/queries/fragments/accessMenuFragment'
import tagBannersFragment from 'api/queries/fragments/tagBannersFragment'
import dataCardsFragment from 'api/queries/fragments/dataCardsFragment'
import latestNewsFragment from 'api/queries/fragments/latestNewsFragment'
import phoneTagsFragment from 'api/queries/fragments/phoneTagsFragment'
import phoneTagsWithModalFragment from 'api/queries/fragments/phoneTagsWithModalFragment'
import textAndLinkWithRightImageFragment from 'api/queries/fragments/textAndLinkWithRightImageFragment'
import planWidgetFragment from 'api/queries/fragments/planWidgetFragment'
import miniBannerFragment from 'api/queries/fragments/miniBannerFragment'
import carouselLinksFragment from 'api/queries/fragments/carouselLinksFragment'
import searchWidgetFragment from 'api/queries/fragments/searchWidgetFragment'
import tagTransparentBannersFragment from 'api/queries/fragments/tagTransparentBannersFragment'
import titleAndDescriptionFragment from 'api/queries/fragments/titleAndDescriptionFragment'
import planOptionFragment from 'api/queries/fragments/planOptionFragment'
import htmlItemListImageFragment from 'api/queries/fragments/htmlItemListImageFragment'
import informationTagFragment from 'api/queries/fragments/informationTagFragment'
import informationTagsFragment from 'api/queries/fragments/informationTagsFragment'
import informationTagWrapperFragment from 'api/queries/fragments/informationTagWrapperFragment'
import cardFragment from 'api/queries/fragments/cardFragment'
import cardsFragment from 'api/queries/fragments/cardsFragment'
import formFragment from 'api/queries/fragments/formFragment'
import halfBannersMosaicFragment from 'api/queries/fragments/halfBannersMosaicFragment'
import informationGridFragment from 'api/queries/fragments/informationGridFragment'
import informationCardFragment from 'api/queries/fragments/informationCardFragment'
import informationCardWithIconFragment from 'api/queries/fragments/informationCardWithIconFragment'
import faqWidgetFragment from 'api/queries/fragments/faqWidgetFragment'
import highlightedNewsFragment from 'api/queries/fragments/highlightedNewsFragment'
import faqListFragment from 'api/queries/fragments/faqListFragment'
import downloadButtonFragment from 'api/queries/fragments/downloadButtonFragment'
import stepFragment from 'api/queries/fragments/stepFragment'
import stepByStepFragment from 'api/queries/fragments/stepByStepFragment'
import paragraphWebform from 'api/queries/fragments/webformFragment'
import serviceWidgetFragment from 'api/queries/fragments/serviceWidgetFragment'
import imagesMosaicFragment from 'api/queries/fragments/imagesMosaicFragment'
import carouselTagBannersFragment from 'api/queries/fragments/carouselTagBannersFragment'
import contentListFragment from 'api/queries/fragments/contentListFragment'
import downloadListFragment from 'api/queries/fragments/downloadListFragment'
import bigButtonsWithBannerFragment from 'api/queries/fragments/bigButtonsWithBannerFragment'
import staffGalleryFragment from 'api/queries/fragments/staffGalleryFragment'
import appFragment from 'api/queries/fragments/appFragment'
import publicAppFragment from 'api/queries/fragments/publicAppFragment'
import bennerApplicationFragment from 'api/queries/fragments/bennerApplicationFragment'
import externalApplicationFragment from 'api/queries/fragments/externalApplicationFragment'
import bannerWithMosaicFragment from 'api/queries/fragments/bannerWithMosaicFragment'
import mediaFullFragment from 'api/queries/fragments/mediaFullFragment'
import audioFragment from 'api/queries/fragments/audioFragment'
import audioGridFragment from 'api/queries/fragments/audioGridFragment'
import textCarouselWithImageFragment from 'api/queries/fragments/textCarouselWithImageFragment'
import mediaCarouselFragment from 'api/queries/fragments/mediaCarouselFragment'
import sectionTitleFragment from 'api/queries/fragments/sectionTitleFragment'
import anchorFragment from 'api/queries/fragments/anchorFragment'
import loginBoxFragment from 'api/queries/fragments/loginBoxFragment'
import sitemapWidgetFragment from 'api/queries/fragments/sitemapWidgetFragment'
import latestInformativeFragment from 'api/queries/fragments/latestInformativeFragment'
import quickAccessFragment from 'api/queries/fragments/quickAccessFragment'
import scheduleWidgetProtocolsFragment from 'api/queries/fragments/scheduleWidgetProtocolsFragment'
import scheduleWidgetFeesFragment from 'api/queries/fragments/scheduleWidgetFeesFragment'
import atosApplicationFragment from 'api/queries/fragments/atosApplicationFragment'
import imageGalleryFragment from 'api/queries/fragments/imageGalleryFragment'
import flipbookFragment from 'api/queries/fragments/flipbookFragment'
import newsWidgetFragment from 'api/queries/fragments/newsWidgetFragment'
import mapFragment from 'api/queries/fragments/mapFragment'
import glossaryWidgetFragment from 'api/queries/fragments/glossaryWidgetFragment'
import definitionWidgetFragment from 'api/queries/fragments/definitionWidgetFragment'
import buttonFragment from './buttonFragment'
import countdownFragment from './countdownFragment'
import extendedImageFragment from './extendedImageFragment'
import extendedImagesGridFragment from './extendedImagesGridFragment'
import postitFragment from './postitFragment'
import postitsFragment from './postitsFragment'
import videoFragment from './videoFragment'
import videoGridFragment from './videoGridFragment'
import wideBannerFragment from './wideBannerFragment'
import cronogramaPrestadorFragment from './cronogramaPrestadorFragment'
import photoGalleryFragment from './photoGalleryFragment'
import photoGalleryWidgetFragment from './photoGalleryWidgetFragment'
import shareButtonsFragment from 'api/queries/fragments/shareButtonsFragment'

export default `
fragment AllParagraphs on Paragraph{
    ...ParagraphAccessMenu
    ...ParagraphAnchor
    ...ParagraphApp
    ...ParagraphAtosApplication
    ...ParagraphAudio
    ...ParagraphAudioGrid
    ...ParagraphBannerWithMosaic
    ...ParagraphBasicBanner
    ...ParagraphBennerApplication
    ...ParagraphBigButton
    ...ParagraphBigButtonsWithBanner
    ...ParagraphButton
    ...ParagraphCard
    ...ParagraphCards
    ...ParagraphExtendedImage
    ...ParagraphExtendedImagesGrid
    ...ParagraphCarouselBigButton
    ...ParagraphCarouselLinks
    ...ParagraphCarouselTagBanners
    ...ParagraphCountdown
    ...ParagraphDataCard
    ...ParagraphDataCards
    ...ParagraphDefinitionWidget
    ...ParagraphDescriptionWithIcon
    ...ParagraphDownloadButton
    ...ParagraphContentList
    ...ParagraphDownloadList
    ...ParagraphExternalApplication
    ...ParagraphFaqList
    ...ParagraphFaqWidget
    ...ParagraphFlipbook
    ...ParagraphForm
    ...ParagraphGlossaryWidget
    ...ParagraphHalfBanner
    ...ParagraphHalfBanners
    ...ParagraphHalfBannersMosaic
    ...ParagraphHighlightedNews
    ...ParagraphHtmlItemListImage
    ...ParagraphImageGallery
    ...ParagraphImagesMosaic
    ...ParagraphInformationCard
    ...ParagraphInformationCardWithIcon    
    ...ParagraphInformationGrid
    ...ParagraphInformationTag
    ...ParagraphInformationTags
    ...ParagraphInformationTagWrapper
    ...ParagraphLatestInformative
    ...ParagraphLatestNews
    ...ParagraphLinkListLeftImage
    ...ParagraphLinkWithIcon
    ...ParagraphLinkWithTitleAndSubtitle
    ...ParagraphLoginBox
    ...ParagraphMap
    ...ParagraphMediaCarousel
    ...ParagraphMediaFull
    ...ParagraphMiniBanner
    ...ParagraphNewsWidget
    ...ParagraphPhoneTag
    ...ParagraphPhoneTags
    ...ParagraphPhoneTagsWithModal
    ...ParagraphPlanOption
    ...ParagraphPlanWidget
    ...ParagraphPostit
    ...ParagraphPostits
    ...ParagraphPublicApp
    ...ParagraphQuickAccess
    ...ParagraphScheduleWidgetFees
    ...ParagraphScheduleWidgetProtocols
    ...ParagraphSearchWidget
    ...ParagraphSectionTitle
    ...ParagraphServiceWidget
    ...ParagraphSetBigButton
    ...ParagraphSitemapWidget
    ...ParagraphStaffGallery
    ...ParagraphStepByStep
    ...ParagraphStep
    ...ParagraphTagBanner
    ...ParagraphTagBanners
    ...ParagraphTagTransparentBanner
    ...ParagraphTagTransparentBanners
    ...ParagraphTextAndLinkWithRightImage
    ...ParagraphTextCarouselWithImage
    ...ParagraphTitleAndDescription
    ...ParagraphVideo
    ...ParagraphVideoGrid
    ...ParagraphWebform
    ...ParagraphWideBanner
    ...ParagraphCronogramaPrestador
    ...ParagraphPhotoGallery
    ...ParagraphPhotoGalleryWidget
    ...ParagraphShareButtons
}

  ${basicBannerFragment}
  ${mediaFragment}
  ${halfBannersFragment}
  ${carouselBigButtonFragment}
  ${setBigButtonFragment}
  ${linkListLeftImageFragment}
  ${linkWithTitleAndSubtitleFragment}
  ${accessMenuFragment}
  ${tagBannersFragment}
  ${dataCardsFragment}
  ${latestNewsFragment}
  ${phoneTagsFragment}
  ${phoneTagsWithModalFragment}
  ${textAndLinkWithRightImageFragment}
  ${planWidgetFragment}
  ${miniBannerFragment}
  ${carouselLinksFragment}
  ${tagTransparentBannersFragment}
  ${searchWidgetFragment}
  ${titleAndDescriptionFragment}
  ${planOptionFragment}
  ${htmlItemListImageFragment}
  ${informationTagFragment}
  ${informationTagsFragment}
  ${informationTagWrapperFragment}
  ${cardFragment}
  ${cardsFragment}
  ${formFragment}
  ${halfBannersMosaicFragment}
  ${informationGridFragment}
  ${informationCardFragment}
  ${informationCardWithIconFragment}
  ${faqWidgetFragment}
  ${highlightedNewsFragment}
  ${faqListFragment}
  ${downloadButtonFragment}
  ${stepFragment}
  ${stepByStepFragment}
  ${paragraphWebform}
  ${serviceWidgetFragment}
  ${imagesMosaicFragment}
  ${bigButtonsWithBannerFragment}
  ${carouselTagBannersFragment}
  ${staffGalleryFragment}
  ${appFragment}
  ${publicAppFragment}
  ${bennerApplicationFragment}
  ${externalApplicationFragment}
  ${contentListFragment}
  ${downloadListFragment}
  ${mediaFullFragment}
  ${audioGridFragment}
  ${audioFragment}
  ${textCarouselWithImageFragment}
  ${bannerWithMosaicFragment}
  ${anchorFragment}
  ${sectionTitleFragment}
  ${mediaCarouselFragment}
  ${loginBoxFragment}
  ${sitemapWidgetFragment}
  ${latestInformativeFragment}
  ${quickAccessFragment}
  ${scheduleWidgetProtocolsFragment}
  ${scheduleWidgetFeesFragment}
  ${atosApplicationFragment}
  ${imageGalleryFragment}
  ${descriptionWithIconFragment}
  ${flipbookFragment}
  ${mapFragment}
  ${newsWidgetFragment}
  ${glossaryWidgetFragment}
  ${definitionWidgetFragment}
  ${buttonFragment}
  ${countdownFragment}
  ${extendedImageFragment}
  ${extendedImagesGridFragment}
  ${postitFragment}
  ${postitsFragment}
  ${videoFragment}
  ${videoGridFragment}
  ${wideBannerFragment}
  ${cronogramaPrestadorFragment}
  ${photoGalleryFragment}
  ${photoGalleryWidgetFragment}
  ${shareButtonsFragment}
`
