import { gql } from 'graphql-request'

export default gql`
	query getOutpatientAuthorizationConsultation($providerCode: String!, $authorizationCode: String!) {
		applications {
            outpatientAuthorizations {
                outpatientAuthorizationsResult( providerCode: $providerCode, authorizationCode: $authorizationCode) {
                    recipient
                    type_code
                    z_name
                    card_number
                    authorization_code
                    authorizations {
                        number
                        due_date
                        procedure_code
                        procedure_description
                        degree
                        authorized_quantity
                        authorized_payed
                        current_date
                        executor
                        degree_participation
                    }
                }
            }
        }
	}
`