export default `
fragment Media on Media {
  ...on MediaImage {
    entityBundle
    images {
      title
      alt
      size32: size32x32 {
        url
      }
      size48: size48x48 {
        url
      }
      small: thumbnail {
        url
      }
      medium {
        url
      }
      large {
        url
      }
      wide {
        url
      }
    }
  }
  ... on MediaDocument {
    created
    entityBundle
    title
    fieldMediaDocument {
      entity {
        filename
        url
      }
    }
  }
  
  ...on MediaRemoteAudio {
    entityBundle
    fieldMediaOembedAudio
  }
  
  ...on MediaAudio {
    entityBundle
    fieldMediaAudioFile {
      entity {
        filename
        url
      }
    }
  }  
  
  ...on MediaVideo {
    entityBundle
    title
    fieldMediaVideoFile {
      entity {
        filename
        url
      }
    }
  }
  
  ...on MediaRemoteVideo {
    entityBundle
    fieldMediaOembedVideo
  }  
  
  ...on MediaSvg {
    entityBundle
    fieldMediaFile {
      entity {
        filename
        url
      }
    }
  }  
}

`
