import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import renderComponents from 'helpers/renderComponents'
import { Container, Row, Col } from 'react-bootstrap'
import { snakeToPascal } from 'helpers/strings'
import dataGa from 'helpers/dataGa'
import get from 'lodash.get'
import NavBar from 'components/organisms/NavBar'
import Footer from 'components/organisms/Footer'
import AccessibilityBar from 'components/organisms/AccessibilityBar'
import { DateTime } from 'luxon'
import Breadcrumb from 'components/atoms/Breadcrumb'
import ShareButtons from 'components/molecules/ShareButtons'
import PerfilBar from 'components/organisms/PerfilBar'
import { userCheckMobileScreen } from 'helpers/tools'

const CustomPages = (props) => {
  const { data, user, refs } = props
  const entity = get(data, 'route.entity') || { content: [] }
  const view = get(entity, 'view') || 'home'
  const logged = get(entity, 'restricted')
  const components = Array.isArray(entity.content) ? entity.content : []
  const header = Array.isArray(entity.header) ? entity.header : []
  const [acessibilityMode, setAcessibilityMode] = useState(false)
  const hasHeader = header.length > 0
  const breadcrumb = get(data, 'route.entity.breadcrumb') || {}
  const customView = ['news', 'faq', 'plan', 'page', 'download','album'].includes(view)
  const isEmbeded = get(entity, 'embeded')
  const location = useLocation()
  let headline = false

  let require_login = location.pathname.indexOf('/app') === 0 ? true : logged
  if (customView && view != 'plan') {
    const title = get(entity, 'title')
    const category = get(entity, 'category[0].entity.name')
    const created = get(entity, 'created')
    const updated = get(entity, 'updated')
    headline = {
      title: title,
      subtitle: category,
      created: created ? DateTime.fromJSDate(new Date(created * 1000)).toFormat("dd/MM/yyyy H:mm'h'") : '',
      updated: updated ? DateTime.fromJSDate(new Date(updated * 1000)).toFormat("dd/MM/yyyy H:mm'h'") : '',
    }
  }

  const RenderedPage = () => {
    if (customView) {
      let RenderPage = renderComponents(snakeToPascal(view))
      return <RenderPage data={data} user={user} refs={refs} />
    } else {
      return ''
    }
  }

  const Header = () => {
    return (
      <>
        <AccessibilityBar refs={refs} />
        <NavBar className="bg-green-dark-500" logged={require_login} user={user} refs={refs} />
      </>
    )
  }

  const renderBreadcrumb = (data) => {
    const theme = get(components[0], 'entity.theme.entity.classes') || []
    return (
      Array.isArray(data) &&
      data.map((item, index) => {
        const page = get(item, 'title')
        return <Breadcrumb url={get(item, 'url')} page={page} key={index + 1} color={theme} />
      })
    )
  }

  useEffect(() => {
    dataGa(entity.dataGa)
    setAcessibilityMode(JSON.parse(localStorage.getItem('acessibility-mode')) ? true : false)
  }, [])

  return (
    <>
      <header id="header" ref={refs.header}>
        {!hasHeader || isEmbeded ? (
          <Header />
        ) : (
          header
            .filter((i) => i.entity)
            .map(({ entity }, index) => {
              const propsItem =
                entity.entityBundle === 'from_library' ? entity.entities?.entity?.paragraphs.entity : entity

              if (propsItem) {
                const RenderComponent = renderComponents(snakeToPascal(get(propsItem, 'entityBundle')))
                return (
                  <RenderComponent
                    key={index + propsItem.uuid}
                    data={propsItem}
                    headline={headline}
                    user={user}
                    logged={require_login}
                    refs={refs}
                  />
                )
              }
              return <Header />
            })
        )}
        {require_login && ((isEmbeded && !userCheckMobileScreen()) || !isEmbeded) && (
          <PerfilBar title={entity.title} user={user} />
        )}
      </header>
      <div>
        <main id="content" ref={refs.content} tabIndex="-1">
          {location.pathname !== '/' && (
            <Container>
              <Row>
                <Col>
                  <div className="breadcrumbz">{renderBreadcrumb(breadcrumb)}</div>
                </Col>
              </Row>
            </Container>
          )}
          {RenderedPage()}
          {components
            .filter((i) => i.entity)
            .map((item, index) => {
              let propsItem =
                item.entity.entityBundle === 'from_library'
                  ? item.entity.entities?.entity?.paragraphs.entity
                  : item.entity

              if (propsItem) {
                propsItem.acessibilityMode = acessibilityMode
                propsItem.breadcrumb = breadcrumb

                if (index === 0) propsItem.first = true
                if (index === components.length - 1) propsItem.last = true

                const RenderComponent = renderComponents(snakeToPascal(get(propsItem, 'entityBundle')))
                return <RenderComponent key={index + propsItem.uuid} data={propsItem} user={user} refs={refs} />
              }
            })}
          {view === 'news' && <ShareButtons />}
        </main>
      </div>
      <div>
        <footer>{!isEmbeded && <Footer refs={refs} />}</footer>
      </div>
    </>
  )
}

export default CustomPages
