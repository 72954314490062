import { gql } from 'graphql-request'

import allParagraphs from 'api/queries/fragments/'
import fromLibraryFragment from 'api/queries/fragments/fromLibraryFragment'

export default gql`
  query customPage($pathname: String!, $search: String, $menu_name: String) {
    route(path: $pathname, search: $search, menu_name: $menu_name) {
      redirect_url
      status_code
      message
      entity {
        ...NodeInstitutional
        ...NodeHome
        ...NodeSearch
        ...NodePlan
        ...NodeFaq
        ...NodeApp
        ...NodeService
        ...NodeLandingPage
        ...NodeEvent
        ...NodeNews
        ...NodePage
        ...NodeDownload
        ...NodeAlbum
      }
    }
  }

  fragment NodePage on NodePage {
    uuid
    view
    title
    breadcrumb {
      url
      title
    }
    restricted
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    metaTags {
      key
      value
    }
    header {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    category {
      entity {
        tid
        name
      }
    }
    body {
      value
    }
    media {
      entity {
        ...Media
      }
    }
    content {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
  }

  fragment NodeDownload on NodeDownload {
    uuid
    view
    title
    breadcrumb {
      url
      title
    }
    restricted
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    metaTags {
      key
      value
    }
    header {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    category {
      entity {
        tid
        name
      }
    }
    media {
      entity {
        ...Media
      }
    }
    absoluteLink {
      url {
        path
      }
    }
    content {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
  }

  fragment NodeNews on NodeNews {
    uuid
    view
    title
    breadcrumb {
      url
      title
    }
    restricted
    created
    updated
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    metaTags {
      key
      value
    }
    header {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    category {
      entity {
        tid
        name
      }
    }
    body {
      value
      summary
    }
    media {
      entity {
        ...Media
      }
    }
    downloads {
      entity {
        ...Media
      }
    }
    content {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    gallery {
      entity {
        ...Media
      }
    }
  }

  fragment NodeEvent on NodeEvent {
    uuid
    view
    title
    breadcrumb {
      url
      title
    }
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    metaTags {
      key
      value
    }
    header {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    content {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
  }

  fragment NodeLandingPage on NodeLandingPage {
    uuid
    view
    title
    breadcrumb {
      url
      title
    }
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    metaTags {
      key
      value
    }
    header {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    content {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
  }

  fragment NodeInstitutional on NodeInstitutional {
    uuid
    view
    title
    breadcrumb {
      url
      title
    }
    restricted
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    metaTags {
      key
      value
    }
    header {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    content {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
  }

  fragment NodeService on NodeService {
    uuid
    view
    title
    breadcrumb {
      url
      title
    }
    restricted
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    metaTags {
      key
      value
    }
    body {
      value
    }
    header {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    content {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
  }

  fragment NodeSearch on NodeSearch {
    uuid
    view
    title
    breadcrumb {
      url
      title
    }
    restricted
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    header {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    content {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
  }

  fragment NodeHome on NodeHome {
    uuid
    view
    title
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    metaTags {
      key
      value
    }
    header {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    description
    breadcrumb {
      url
      title
    }
    restricted
    content {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
  }

  fragment NodePlan on NodePlan {
    uuid
    view
    title
    breadcrumb {
      url
      title
    }
    restricted
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    metaTags {
      key
      value
    }
    header {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    preContent {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    coverage {
      entity {
        ...ParagraphAnchor
        ...ParagraphTitleAndDescription
        ...ParagraphInformationCardWithIcon
      }
    }
    coverageTitle
    coverageDescription {
      value
    }
    optionsTitle
    optionsDescription
    options {
      entity {
        ...ParagraphPlanOption
      }
    }
    content {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
  }

  fragment NodeFaq on NodeFaq {
    uuid
    view
    title
    breadcrumb {
      url
      title
    }
    restricted
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    metaTags {
      key
      value
    }
    category {
      entity {
        tid
        name
      }
    }
    body {
      value
      summary
    }
    header {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    content {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
  }

  fragment NodeApp on NodeApp {
    title
    view
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    metaTags {
      key
      value
    }
    uuid
    header {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    embeded
    description
    content {
      entity {
        ...AllParagraphs
        ...ParagraphFromLibrary
      }
    }
    breadcrumb {
      url
      title
    }
    restricted
  }

  fragment NodeSchedule on NodeSchedule {
    uuid
    view
    title
    breadcrumb {
      url
      title
    }
    restricted
    content {
      entity {
        ...ParagraphTitleAndDescription
        ...ParagraphDownloadButton
      }
    }
  }

  fragment NodeAlbum on NodeAlbum {
    uuid
    title
    body {
      value
    }
    view
    medias {
      entity {
        ...Media
      }
    }
    metaTags {
      key
      value
    }
    dataGa {
      data_ga_category {
        data_ga_name
        components {
          component
          data_ga_name
          components {
            component
            data_ga_name
            components {
              component
              data_ga_name
              data_ga_action
              components {
                component
                data_ga_name
                data_ga_action
              }
            }
          }
        }
      }
    }
    breadcrumb {
      title
      url
    }
    header {
      entity {
        ...AllParagraphs
      }
    }
  }

  ${allParagraphs}
  ${fromLibraryFragment}
`
