import React, { useState } from 'react'
import CustomButton from 'components/atoms/CustomButton'
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { printElement } from 'helpers/tools'
import './ShareButtons.scss'

function ShareButtons() {
  const [copiedLink, setCopiedLink] = useState()

  function handlePrintPage() {
    printElement('#content')
  }

  function handleShare() {
    setCopiedLink(true)
    navigator.clipboard.writeText(location.href)
  }

  function handleLeaveCopyLink() {
    setTimeout(() => {
      setCopiedLink(false)
    }, 300)
  }

  return (
    <>
      <Container className="share-buttons d-block d-md-flex py-4">
        <CustomButton
          className="mr-md-3 mb-3 mb-md-0 justify-content-center share-buttons__button"
          color="outline-primary"
          icon="printer"
          onClick={handlePrintPage}
        >
          Imprimir página
        </CustomButton>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{copiedLink ? 'Link copiado!' : 'Copiar link da página'}</Tooltip>}
        >
          <CustomButton
            className="justify-content-center share-buttons__button"
            color="outline-primary"
            icon="email"
            onClick={handleShare}
            onMouseLeave={handleLeaveCopyLink}
          >
            Compartilhar
          </CustomButton>
        </OverlayTrigger>
      </Container>
      <Container>
        <hr className="border text-gray-form" />
      </Container>
    </>
  )
}

export default ShareButtons
